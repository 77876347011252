import React, {Suspense} from "react";
import "./Project.css";
import Button from "../../components/button/Button";
import {openSource, socialMediaLinks} from "../../portfolio";
import Loading from "../../containers/loading/Loading";
export default function Projects() {

  const FailedLoading = () => null;
  const renderLoader = () => <Loading />;

  if (
    !(typeof repo === "string") &&
    openSource.display
  ) {
    return (
      <Suspense fallback={renderLoader()}>
        <div className="main" id="opensource">
          <h1 className="project-title">Open Source Projects</h1>

          <Button
            text={"More Projects"}
            className="project-button"
            href={socialMediaLinks.github}
            newTab={true}
          />
        </div>
      </Suspense>
    )
  } 
  else {
    return <FailedLoading />;
  }
}
