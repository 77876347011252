import emoji from "react-easy-emoji";
import resume from "./assets/File/MohammadAhmedCV.pdf";

const illustration = {
  animated: true,
};

const greeting = {
  username: "Mohammad Ahmed",
  title: "Hi all, I'm Mohammad Ahmed",
  subTitle: emoji(
    "A passionate MERN Full Stack Software Developer having an experience of designing and building Web and Mobile applications with React.js / Next.js / Javascript / Typescript / Nodejs and some other cool libraries and frameworks."
  ),
  resumeLink: resume,
  displayGreeting: true,
};

// Social Media Links
const socialMediaLinks = {
  github: "https://github.com/maddyahmad/",
  linkedin: "https://www.linkedin.com/in/maddyahmad/",
  gmail: "mohd.ahmed379@gmail.com",
  gitlab: "https://gitlab.com/maddyahmad/",
  facebook: "https://www.facebook.com/maddy.ahmad/",
  instagram: "https://www.instagram.com/maddy.ahmad/",
  twitter: "https://twitter.com/MaddyAhamad",
  display: true,
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle:
    "A MERN stack developer, wielding the power of MongoDB, Express.js, React, and Node.js to create captivating web applications.",
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
    ),
    emoji("⚡ State Management using Redux Toolkit, Zustand and Context API"),
    emoji(
      "⚡ Integration of third party UI libraries such as  Material UI / Mantine UI / Formik / Firebase / Tailwind CSS  / Shadcn etc"
    ),
    emoji(
      "⚡ Implementation of tools like TanStack Query (FKA React Query) for API calls that stale or cache the response data, GraphQL, AWS-SDK for S3 Bucket and Azure Blog Services, Firebase etc"
    ),
  ],

  softwareSkills: [
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5",
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt",
    },
    {
      skillName: "sass",
      fontAwesomeClassname: "fab fa-sass",
    },
    {
      skillName: "JavaScript & Typescript",
      fontAwesomeClassname: "fab fa-js",
    },
    {
      skillName: "reactjs & nextjs",
      fontAwesomeClassname: "fab fa-react",
    },
    {
      skillName: "nodejs",
      fontAwesomeClassname: "fab fa-node",
    },
    {
      skillName: "git",
      fontAwesomeClassname: "fab fa-github",
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm",
    },
  ],
  display: true,
};

// Education Section
const educationInfo = {
  display: true,
  schools: [
    {
      schoolName: "Graphic Era Hill University",
      logo: require("./assets/images/geulogo.png"),
      subHeader: "Bachelor of Technology in Mechanical Engineering",
      duration: "August 2013 - July 2017",
      desc: "Ranked top 10% in the program.",
      descBullets: [
        " Completed Project on Solar AC Technolgy and completed one month internship at Ordinance Factory Board ( OLF ) in the fields Macine Control and Production",
      ],
    },
    {
      schoolName: "Jagran Law College",
      logo: require("./assets/images/jagran-logo.png"),
      subHeader: "Bachelors of Law (LLB)",
      duration: "August 2018 - October 2021",
    },
    // {
    //   schoolName: "Doon Public Senior Secondary School",
    //   logo: require("./assets/images/jagran-logo.png"),
    //   subHeader: "Intermediate (Grade-12)",
    //   duration: "April 2012 - May 2013",
    // },
    // {
    //   schoolName: "Doon Public School",
    //   logo: require("./assets/images/jagran-logo.png"),
    //   subHeader: "High School (Grade-10)",
    //   duration: "April 2010 - May 2011",
    // }
  ],
};

const techStack = {
  viewSkillBars: true,
  experience: [
    {
      Stack: "Frontend / Design",
      progressPercentage: "75%",
    },
    {
      Stack: "Backend",
      progressPercentage: "50%",
    },
    {
      Stack: "Devops",
      progressPercentage: "50%",
    },
  ],
  displayCodersrank: true,
};

const workExperiences = {
  display: true,
  experience: [
    {
      role: "Software Development Engineer - SDE III  ( React.js )",
      company: "MobCoder Technologies Pvt Ltd",
      companylogo: require("./assets/images/mobcoder.jpg"),
      date: "April 2024 - Present",
      desc: "I am working as a Senior Frontend Developer using Javascript libraries like React Js, Next Js, Node Js etc",
      descBullets: [
        "I recently joined MobCoder and I am excited for the opportunities that lies ahead.",
        "Location : Riyadh, Saudi Arabia (on-site).",
      ],
    },
    {
      role: "Software Consultant",
      company: "Cynoteck Technology Solutions Private Limited",
      companylogo: require("./assets/images/cynoteckLogo.png"),
      date: "October 2021 – April 2024",
      desc: "I am working as an experienced MERN full stack developer.",
      descBullets: [
        "Worked on SaaS products",
        "Developed high performance websites",
        "Lead team of 5 frontend developers working in a product",
        "Worked on multiple libraries and frameworks to develop high scaled products",
        "Achievement : Won employee of the month award twice",
      ],
    },
    {
      role: "Software Developer",
      company: "LorryGo Technology Private Limited",
      companylogo: require("./assets/images/lorrygo.jpg"),
      date: "January 2020 – June 2021",
      desc: "I worked as a web developer for 1.5 years using reactjs, html, css, javascript and various ui libraries",
    },
  ],
};

const openSource = {
  githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN,
  githubUserName: "maddyahmad",
  showGithubProfile: "true",
  display: false,
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "Some major projects that I have worked on",
  projects: [
    {
      image: require("./assets/images/onekeymls-logo.webp"),
      projectName: "Onekey LMS (Property Search)",
      projectDesc:
        "OneKey® MLS is The One source real estate marketplace with The Best Data, Expertise, and Innovation. They provide  listings in metropolitan New York, straight from the largest REALTOR®-run MLS in the Empire state.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://onekeymls.com/",
        },
        //  you can add extra buttons here.
      ],
    },
    {
      image: require("./assets/images/opencounty.png"),
      projectName: "OI SaaS County",
      projectDesc:
        "The Open County portal has been developed for the Council of Governors by the Open Institute with support from the World Bank’s Kenya Accountable Devolution Program and funding from Danida, DFID, EU, Finland, Sida, and USAID.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://opencounty.org/",
        },
        //  you can add extra buttons here.
      ],
    },
    {
      image: require("./assets/images/gaspos.jpg"),
      projectName: "GasPos",
      projectDesc: `Gas Pos: Fuel POS + EMV Pumps For Gas Stations & truck Stops Across USA. With the Gas Pos Equipment as a Service Program, you will receive the fastest-growing fuel point of sale system, EMV fuel dispensers, and the most advanced back office software for gas stations & truck stops.`,
      footerLink: [
        {
          name: "Visit Website",
          url: "https://www.gaspos.co/",
        },
      ],
    },
    {
      image: require("./assets/images/sportifi.webp"),
      projectName: "Sportifi",
      projectDesc:
        "Sportifi is India's new Marketplace with Organisers hosting online and offline events in Indian cities.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://sportifi.in/all",
        },
      ],
    },
    {
      image: require("./assets/images/coinsuites.jpg"),
      projectName: "Coinsuites",
      projectDesc:
        "on a mission to help entrepreneurs and small businesses find real growth through building Fundable businesses and accessing capital.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://onboarding.coinsuites.com/",
        },
      ],
    },
    {
      image: require("./assets/images/tpologo.png"),
      projectName: "The Patient Offer",
      projectDesc:
        "TPO's “Willingness-to-Pay” Quotient of Payers is a proactive approach that helps move the decisions to the front end of care spectrum. Thereby, enhance the overall Patient engagement experience & Provider’s bottom line.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://thepatientoffer.com/index.html",
        },
      ],
    },
    {
      image: require("./assets/images/ThePBNetworkLogo.webp"),
      projectName: "The PB Network",
      projectDesc:
        "The PB Network provides meaningful daily activities for Day Program participants, Residences, and individuals to participate in community involvement, gaining confidence, working with others, sharing ideas, developing verbal, and writing skills and having fun!",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://thepbnetwork.com/",
        },
      ],
    },
  ],
  display: true,
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "MERN Stack Developer",
      subtitle: "In Progress.",
      image: require("./assets/images/codeInLogo.webp"),
      footerLink: [
        {
          name: "Certification",
          url: "",
        },
        {
          name: "Award Letter",
          url: "",
        },
        {
          name: "Blog",
          url: "",
        },
      ],
    },
    {
      title: "Python",
      subtitle: "In Progress.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      footerLink: [
        {
          name: "View Google Assistant Action",
          url: "",
        },
      ],
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      footerLink: [
        { name: "Certification", url: "" },
        {
          name: "Final Project",
          url: "",
        },
      ],
    },
  ],
  display: false,
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(""),

  talks: [
    {
      title: "",
      subtitle: "",
      slides_url: "",
      event_url: "",
    },
  ],
  display: false,
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [""],
  display: false,
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+91-8126377488",
  email_address: "mohd.ahmed379@gmail.com",
};

// Twitter Section

const twitterDetails = {
  userName: "MaddyAhamad",
  display: true,
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
};
